const scoreMapPws =   {
    
     "2": { //age
        "Default": 0, 
        "A": 0,
        "B": 90,
        "C": 95,
        "D": 100,
        "E": 95,
        "F": 90,
        "G": 85,
        "H": 80,
        "I": 75,
        "J": 70,
        "K": 65,
        "L": 60,
        "M": 55,
        "N": 50,
        "O": 45,
        "P": 35,
        "Q": 25,
        "R": 15,
        "S": 5,
        "T": 0
    },

    "3": { //qualification
        "A": 0,
        "B": 28,
        "C": 84,
        "D": 91,
        "E": 112,
        "F": 119,
        "G": 126,
        "H": 140
    },

    "FL":{          // question 6 and 7
        "10": 32,
        "9": 29,
        "8": 22,
        "7": 16,
        "6": 8,
        "5": 6,
        "4": 6,
        "3": 0
    },

    "SL":{
        "10": 6,
        "9": 6,
        "8": 3,
        "7": 3,
        "6": 1,
        "5": 1,
        "4": 0,
        "3": 0
    },

    "8":{ //canada exp
    "A": 0,
    "B": 35,
    "C": 46,
    "D": 56,
    "E": 63,
    "F": 70
    },
    
    "9":{  //Spouse qualifications
        "A": 0,
        "B": 2,
        "C": 6,
        "D": 7,
        "E": 8,
        "F": 9,
        "G": 10,
        "H": 10
    },
    "10":{ // spouse language
        "10": 5,
        "9": 5,
        "8": 3,
        "7": 3,
        "6": 1,
        "5": 1,
        "4": 0,
        "3": 0
    },
    "11":{ //spouse canada exp
        "A": 0,
        "B": 5,
        "C": 7,
        "D": 8,
        "E": 9,
        "F": 10
    }
}

const scoreMapPWOS = {
    "2":{ //age
        "A": 0,
        "B": 99,
        "C": 105,
        "D": 110,
        "E": 105,
        "F": 99,
        "G": 94,
        "H": 88,
        "I": 83,
        "J": 77,
        "K": 72,
        "L": 66,
        "M": 61,
        "N": 55,
        "O": 50,
        "P": 39,
        "Q": 28,
        "R": 17,
        "S": 6,
        "T": 0
    },
    "3":{
        "A": 0,
        "B": 30,
        "C": 90,
        "D": 98,
        "E": 120,
        "F": 128,
        "G": 135,
        "H": 150
    },
    "8":{ //canada exp
    "A": 0,
    "B": 40,
    "C": 53,
    "D": 64,
    "E": 72,
    "F": 80
    },
    "FL":
    {
        "10": 34,
        "9": 31,
        "8": 23,
        "7": 17,
        "6": 9,
        "5": 6,
        "4": 6,
        "3": 0
    },
    "SL":{
        "10": 6,
        "9": 6,
        "8": 3,
        "7": 3,
        "6": 1,
        "5": 1,
        "4": 0,
        "3": 0
    }
}

const secDScore = {
    "Sk_Case2":{ //same for both skill 1 and 2
        "A": 0,
        "B": 0,
        "C": 13,
        "D": 13,
        "E": 13,
        "F": 25,
        "G": 25,
        "H": 25
    },
    "Sk_Case1":{
        "A": 0,
        "B": 0,
        "C": 25,
        "D": 25,
        "E": 25,
        "F": 50,
        "G": 50,
        "H": 50
    },
    "Sk34_Case2":{
        "A": 0,
        "B": 13,
        "C": 13,
        "D": 25
    },
    "Sk34_Case1":{
        "A": 0,
        "B": 25,
        "C": 25,
        "D": 50
    }

}

export { scoreMapPws, scoreMapPWOS,secDScore };